<template>
  <div id="mapClient" class="mapcontainer"></div>
</template>

<script>
//
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import "../../node_modules/leaflet-geosearch/dist/geosearch.css";
import { EsriProvider } from "leaflet-geosearch";
//import { BingProvider } from "leaflet-geosearch";
//import { HereProvider } from "leaflet-geosearch";

import { GeoSearchControl } from "leaflet-geosearch";

export default {
  name: "StoreMap",
  components: {},
  data() {
    return {
      map_location: null,
      location: null,
    };
  },
  props: {
    storeLocation: null,
    recenter: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  methods: {
    getLocation() {
      return new Promise((resolve, reject) => {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(
            (res) => {
              this.location = [res.coords.latitude, res.coords.longitude];
              resolve(this.location);
            },
            (err) => {
              console.log(err);
              this.location = null;
              reject(err);
            }
          );
        } else {
          reject("No Geolocation support.");
        }
      });
    },
    initMap() {
      var layer;
      //const BING_KEY ="Arppl2TYkv2B2rMaI8T6x4Jwt-Yvk_mKuL1C4ngjOkKSXoPYVCdT5rYaYZgUX4CA";
      //const HEREKEY = "_5kfPl6OjGTRMvgP5evdNY5nPBBwRVfQanf9zYDJorc";
      // var osmUrl =
      //     "https://tiles.stadiamaps.com/tiles/outdoors/{z}/{x}/{y}{r}.png",
      //   osmAttrib =
      //     '&copy; <a href="https://stadiamaps.com/">Stadia Maps</a>, &copy; <a href="https://openmaptiles.org/">OpenMapTiles</a> &copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors';

      // var osmUrl =
      //   "https://tiles.stadiamaps.com/tiles/outdoors/{z}/{x}/{y}{r}.png",
      //   osmAttrib =
      //     '&copy; <a href="https://stadiamaps.com/">Stadia Maps</a>, &copy; <a href="https://openmaptiles.org/">OpenMapTiles</a> &copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors';

      // var osmUrl =
      //   "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}",
      //   osmAttrib =
      //     'Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community';

      // var osmUrl =
      //     "https://tiles.stadiamaps.com/tiles/alidade_smooth/{z}/{x}/{y}{r}.png",
      //   osmAttrib =
      //     '&copy; <a href="https://stadiamaps.com/">Stadia Maps</a> contributors';

      var osmUrl = "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
        osmAttrib =
          '&copy; <a href="https://openstreetmap.org/copyright">OpenStreetMap</a> contributors';

      // var osmUrl =
      //     "https://2.base.maps.ls.hereapi.com/maptile/2.1/maptile/newest/normal.day/{z}/{x}/{y}/512/png8?apiKey=" +
      //     HEREKEY +
      //     "&ppi=320",
      //   osmAttrib = "&copy; HERE 2019";

      // var osmUrl =
      //     "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}",
      //   osmAttrib =
      //     "Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community";

      // var osmUrl = "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      //   osmAttrib =
      //     '&copy; <a href="https://openstreetmap.org/copyright">OpenStreetMap</a> contributors';

      var redIcon = L.icon({
        iconUrl: "https://416-to.ca/pin-red.png",
        iconSize: [50, 50],
      });

      document.getElementById("mapClient").innerHTML =
        "<div id='map' style='height: 100%'></div>";

      //const provider = new OpenStreetMapProvider();
      const provider = new EsriProvider();
      // const provider = new BingProvider({
      //   params: {
      //     key: BING_KEY,
      //   },
      // });

      // const provider = new HereProvider({
      //   params: {
      //     apiKey: HEREKEY,
      //   },
      // });

      const searchControl = new GeoSearchControl({
        provider: provider,
        style: "bar",
        autoComplete: true, // optional: true|false  - default true
        showMarker: false,
        "accept-language": "es", // render results in English
        countrycodes: "ca,co", // limit search results to Canada & United States
      });

      // const searchControl = new Geocoder({
      //   provider: provider,
      //     map: map,
      //     arcgisGeocoder: {
      //       placeholder: "Search",
      //       sourceCountry: "CAN"
      //     }
      //   }, "search");

      this.map = new L.Map("map").setView(this.location, 20);

      const { map } = this;

      L.tileLayer(osmUrl, {
        attribution: osmAttrib,
      }).addTo(map);

      //L.tileLayer.bing(BING_KEY).addTo(map);

      if (layer != undefined) {
        map.removeLayer(layer);
      }

      map.addControl(searchControl);
      // map.addControl(
      //   new GeoSearchControl({
      //     provider,
      //   })
      // );

      map.on("geosearch/showlocation", (e) => {
        var crd = { lat: e.location.y, lng: e.location.x };
        this.$emit("store-location", crd);
      });

      layer = L.marker(this.location, {
        draggable: true,
        icon: redIcon,
      })
        .bindPopup("")
        .addTo(map);
      layer.addTo(map);
      map.setView(this.location, 14);
      map.panTo(layer.getLatLng());

      layer.on("dragend", () => {
        var mco = layer.getLatLng();
        map.setView(mco);
        map.panTo(mco);
        map.dragging.enable();
        console.log(mco);
        this.$emit("store-location", mco);
      });

      layer.on("move", () => {
        map.dragging.disable();
        //this.$emit("location", layer.getLatLng());
        //console.log(layer.getLatLng());
      });
    },
    recenter_map() {
      this.getLocation().then((e) => {
        this.location = e;
        this.initMap();
      });
    },
  },
  watch: {
    map_location: function (e) {
      this.$emit("store-location", e);
    },
    storeLocation: function () {
      if (this.storeLocation) {
        this.location = JSON.parse(this.storeLocation);
        this.initMap();
      } else {
        this.getLocation().then((e) => {
          this.location = e;
          this.initMap();
        });
      }
    },
    recenter: function () {
      this.recenter_map();
    },
  },
  mounted() {
    if (this.storeLocation) {
      this.location = JSON.parse(this.storeLocation);
      this.initMap();
    } else {
      this.getLocation().then((e) => {
        this.location = e;
        this.initMap();
      });
    }
  },
};
</script>

<style scoped>
#map {
  width: 100%;
  z-index: 0;
}

.mapcontainer {
  height: 80vh;
}
</style>
